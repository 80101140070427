import { type ButtonHTMLAttributes, type ReactNode } from "react";
import "./style.scss";

type Props = {
  value: boolean;
  onChange?: (v: boolean) => void;
  children?: ReactNode;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
};

const Checkbox = ({ value, onChange, children, buttonProps }: Props) => {
  const handleChange = (val: boolean) => {
    if (onChange !== undefined) {
      onChange(val);
    }
  };

  return (
    <button
      className="checkbox"
      onClick={() => handleChange(!value)}
      type="button"
      aria-label="Form checkbox"
      {...buttonProps}
    >
      <div className="icon-container">
        {!value && (
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="1" y="0.5" width="19" height="19" rx="4.5" stroke="#B9DCD2" />
          </svg>
        )}
        {value && (
          <svg
            width="20"
            height="20"
            viewBox="11 10.5 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="15.5" y="15" width="12" height="12" rx="3" fill="#B9DCD2" />
            <rect x="12" y="11.5" width="19" height="19" rx="4.5" stroke="#B9DCD2" />
          </svg>
        )}
      </div>
      {children}
    </button>
  );
};

export default Checkbox;
