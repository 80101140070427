import React, { useRef, type HTMLProps } from "react";
import "./style.scss";

type Props = {} & HTMLProps<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, Props>((props, externalRef) => {
  const innerRef = useRef<HTMLInputElement | null>(null);

  return (
    <div className="input-container" onClick={() => innerRef.current?.focus()}>
      <div className="input">
        <input
          {...props}
          ref={(r) => {
            innerRef.current = r;
            if (typeof externalRef === "function") {
              externalRef(r);
            } else {
              if (externalRef) {
                externalRef.current = r;
              }
            }
          }}
        />
      </div>
    </div>
  );
});

export default Input;
